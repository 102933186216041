<template>
    <v-container>
        <ASuccessFour :api="api"/>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-row class="mt-3">
            <v-col>
                <div
                    class="d-flex justify-start">
                    <v-btn
                    @click="()=>{
                        $router.go(-1);
                    }"
                    class="mb-3 mx-0 px-0"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </div>
                    <!-- <v-card class="pa-3" elevation-1> -->
                    <!-- </v-card> -->
                <v-row>
                    <v-col
                        cols="12"
                        sm="6">
                        <v-card
                            class="pa-4">
                            <ComponentCompanyList
                            @selectCompany="(resp)=>{
                                this.companyId = resp;
                            }"/>
                        </v-card>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6">
                        <v-card
                            class="pa-4">
                            <PageSelectForm
                            :companyId="this.companyId"/>
                        </v-card>
                    </v-col>
                </v-row>

            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
import ComponentCompanyList from '../../components/company/ComponentCompanyList.vue';
import PageSelectForm from './PageSelectForm.vue';
export default {
    components:{
    AConfirmation,
    ASuccessFour,
    ComponentCompanyList,
    PageSelectForm,
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            },
        companyId:[],
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = () => {
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
    //
    },
   
    methods: {
        fetch(){

        },
        fetchCompanies() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/company/subscription/"
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){

        },
    }
}
</script>/