var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('AConfirmation',{attrs:{"isShow":_vm.isPending},on:{"cancel":_vm.cancelSubmit,"confirm":_vm.submit}}),(_vm.api.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",attrs:{"type":"table"}}):_vm._e(),_c('ASuccessWithoutReload',{attrs:{"api":this.api},on:{"close":_vm.closeSuccessModal}}),(!_vm.api.isLoading)?_c('v-data-table',{attrs:{"items":_vm.filteredCompanies,"show-select":_vm.showSelect,"search":_vm.search,"item-key":"company_id","headers":_vm.dataHeader},on:{"input":_vm.updateCompanyId},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"text-h4 pt-4",attrs:{"flat":"","height":"auto"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-3 mx-3"},[_c('v-row',{staticClass:"mb-3"},[_c('v-toolbar-title',{staticClass:"font-weight-bold text-h4 my-3"},[_vm._v(" Companies ")])],1),_c('v-row',[_c('v-col',{staticClass:"px-0 pb-0"},[_c('div',[_c('v-text-field',{staticStyle:{"border-radius":"4px 0px 0px 4px"},attrs:{"prepend-inner-icon":"mdi-calendar","label":"Start date","dense":"","outlined":""},on:{"click:prepend-inner":function (){
                                        _vm.isStartDate = true;
                                    },"click":function (){
                                        _vm.isStartDate = true;
                                    }},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('v-dialog',{attrs:{"height":"auto","width":"auto"},model:{value:(_vm.isStartDate),callback:function ($$v) {_vm.isStartDate=$$v},expression:"isStartDate"}},[_c('v-card',{staticClass:"pa-3"},[_c('p',[_vm._v(" Start Date ")]),_c('v-date-picker',{attrs:{"flat":"","landscape":true,"reactive":true},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_vm._t("footer",function(){return [_c('div',{staticClass:"d-flex justify-end white"},[_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function (){
                                                        _vm.isStartDate=false;
                                                        _vm.startDate = null;
                                                    }}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function (){
                                                        _vm.isStartDate = false;
                                                    }}},[_vm._v(" Confirm ")])],1)]})],2)],1)],1)]),_c('v-col',{staticClass:"px-0 pb-0"},[_c('div',[_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"prepend-inner-icon":"mdi-calendar","label":"End Date","dense":"","outlined":""},on:{"click:prepend-inner":function (){
                                        _vm.isEndDate = true;
                                    },"click":function (){
                                        _vm.isEndDate = true;
                                    }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('v-dialog',{attrs:{"height":"auto","width":"auto"},model:{value:(_vm.isEndDate),callback:function ($$v) {_vm.isEndDate=$$v},expression:"isEndDate"}},[_c('v-card',{staticClass:"pa-3"},[_c('p',[_vm._v(" End Date ")]),_c('v-date-picker',{attrs:{"flat":"","landscape":true,"reactive":true},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('div',{staticClass:"footer",attrs:{"name":"footer"}},[_c('div',{staticClass:"d-flex justify-end my-3"},[_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":function (){
                                                        _vm.isEndDate = false;
                                                        _vm.endDate = null;
                                                    }}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function (){
                                                        _vm.isEndDate = false;
                                                    }}},[_vm._v(" Confirm ")])],1)])],1)],1)],1)])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 mb-4",attrs:{"align-self":"right"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.filterCompaniesSubscriptionDate}},[_vm._v(" Filter ")])],1)])],1),_c('v-row',[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
                                                    var item = ref.item;
return [(item.created_at !=null)?_c('span',[_vm._v(" "+_vm._s(_vm.convertTimeZone(item.created_at))+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.company_id",fn:function(ref){
                                                    var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'PageCompaniesDetail',params:{id:item.company_id}}}},[_vm._v(" "+_vm._s(item.company_id)+" ")])]}}],null,true),model:{value:(_vm.selectedCompanies),callback:function ($$v) {_vm.selectedCompanies=$$v},expression:"selectedCompanies"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }