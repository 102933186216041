<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <ASuccessWithoutReload  @close="closeSuccessModal" :api="this.api"/>
        <v-data-table
            v-if="!api.isLoading"
            :items="filteredCompanies"
            :show-select="showSelect"
            :search="search"
            item-key="company_id"
            v-model="selectedCompanies"
            @input="updateCompanyId"
            :headers="dataHeader">
            <template
                v-slot:top>
                <v-toolbar
                    flat
                    class="text-h4 pt-4"
                    height="auto">
                    <v-row
                        no-gutters>
                        <v-col
                            class="mt-3 mx-3">
                            <v-row
                                class="mb-3">
                                <v-toolbar-title
                                    class="font-weight-bold text-h4 my-3">
                                    Companies
                                </v-toolbar-title>
                            </v-row>
                            <v-row>
                                <v-col
                                    class="px-0 pb-0">
                                    <div>
                                        <v-text-field prepend-inner-icon="mdi-calendar" v-model="startDate" style="border-radius:4px 0px 0px 4px" @click:prepend-inner="()=>{
                                            isStartDate = true;
                                        }" label="Start date" @click="()=>{
                                            isStartDate = true;
                                        }" dense outlined>
        
                                        </v-text-field>
                                        <v-dialog v-model="isStartDate" height="auto" width="auto">
                                            <v-card class="pa-3">
                                                <p>
                                                    Start Date
                                                </p>
                                                <v-date-picker flat v-model="startDate" :landscape="true" :reactive="true">
        
                                                </v-date-picker>
                                                <slot name="footer">
                                                    <div class="d-flex justify-end white" >
                                                        <v-btn color="primary" plain @click="()=>{
                                                            isStartDate=false;
                                                            startDate = null;
                                                        }">
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn color="primary" @click="()=>{
                                                            isStartDate = false;
                                                        }">
                                                            Confirm
                                                        </v-btn>
                                                    </div>
        
                                                </slot>
                                            </v-card>
                                        </v-dialog>
                                    </div>
                                </v-col>
                                <v-col
                                    class="px-0 pb-0">
                                    <div>
                                        <v-text-field @click:prepend-inner="()=>{
                                            isEndDate = true;
                                        }" prepend-inner-icon="mdi-calendar" @click="()=>{
                                            isEndDate = true;
                                        }" style="border-radius:0px" label="End Date" v-model="endDate"  dense outlined>
        
                                        </v-text-field>
                                        <v-dialog v-model="isEndDate" height="auto" width="auto">
                                            <v-card class="pa-3">
                                                <p>
                                                    End Date
                                                </p>
                                                <v-date-picker flat v-model="endDate" :landscape="true" :reactive="true">
                                                </v-date-picker>
                                                <div name="footer" class="footer">
                                                    <div class="d-flex justify-end my-3">
                                                        <v-btn color="primary" plain @click="()=>{
                                                            isEndDate = false;
                                                            endDate = null;
                                                        }">
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn color="primary" @click="()=>{
                                                            isEndDate = false;
                                                        }">
                                                            Confirm
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </v-card>
                                        </v-dialog>
        
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    class="pt-0 mb-4"
                                    align-self="right">
                                <div
                                    class="d-flex justify-end">
                                    <v-btn
                                        @click="filterCompaniesSubscriptionDate"
                                        color="primary">
                                        Filter
                                    </v-btn>
                                </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-text-field
                                    v-model="search"
                                    dense
                                    outlined 
                                    label="Search">
                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template
                v-slot:item.created_at="{item}">
                <span
                    v-if="item.created_at !=null">
                    {{ convertTimeZone(item.created_at) }}
                </span> 
                <span
                    v-else>
                    -
                </span>
            </template>
            <template
                v-slot:item.company_id="{item}">
                <router-link
                    :to="{name:'PageCompaniesDetail',params:{id:item.company_id}}">
                    {{ item.company_id }}
                </router-link>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
export default {
    components:{
    ASuccessWithoutReload
},
    computed: mapState({

}),
    props:[
    //
    ],
    data: () => ({
        startDate:null,
        endDate:null,
        isStartDate:false,
        isEndDate:false,
        search:"",
        selectedCompanies:[],
        data:null,
        showSelect:true,
        filteredCompanies:null,
        dataHeader:[
           
            {
                text:'Id',
                value:'company_id'
            },
            {
                text:'Company',
                value:'name'
            },
            {
                text:'Status',
                value:'verification',
            },
            {
                text:'Created date',
                value:'created_at'
            }
           

        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="GetListOfCompanies") {
                this.data = resp.data;
                this.filteredCompanies = this.$_.clone(resp.data);
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
  
    methods: {
        fetch(){
            this.$api.fetch(this.fetchCompanies());
        },
       
        // BOC : Create a new function to build the api and return it to the fetch function
        fetchCompanies() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API.replace("v1","v2")+"/companies";
            return tempApi;
        },
        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
        },
        updateCompanyId(){
            this.$emit("selectCompany",this.selectedCompanies);
        },
        convertTimeZone(time) {
            return this.$moment(time).format("LL");
        },
        filterCompaniesSubscriptionDate() {
            let tempCompanies = this.$_.clone(this.data);
            let startDate = this.$_.clone(this.startDate);
            let endDate = this.$_.clone(this.endDate);

            if(startDate != null) {
                tempCompanies = tempCompanies.filter(function(item) {
                    let tempCreatedDate = (item.created_at !=null )?item.created_at.split(" ")[0]:null;
                    if(tempCreatedDate >= startDate) {
                        return true;
                    }
                })
            }
            if(endDate != null) {
                tempCompanies = tempCompanies.filter(function(item) {
                    let tempExpiredDate = (item.created_at!=null)?item.created_at.split(" ")[0]:null;
                    if(tempExpiredDate<=endDate) {
                        return true;
                    }
                })
            }
            this.filteredCompanies = tempCompanies;
        }
    }
}
</script>